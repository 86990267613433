/* MENU CONTACTO AGIL */
// INICIALIZAR EL CHATBOT
const btnChatbot = document.querySelector('#chatbot-btn');
const statusChatbot = document.querySelectorAll('.status-chatbot');

// Online status chatbot
const chatbotStatus = (from, to, id, hour, day) => {
  // console.log(day)

  for (let i= 0; i < statusChatbot.length; i++) {
    if(day > 6){
      id[i].classList.add('hide-item');
    } 
    else {
      if (hour < from || hour > to) {
        id[i].classList.add('hide-item');
      }
      else{
        id[i].classList.remove('hide-item');
      }
    }
  }
}

const getTime = () => {
  const fecha = new Date(); 
  const dia = fecha.getDay(); //local day
  const hora = fecha.getHours(); //local hours
  const minutos = fecha.getMinutes(); //local minutes

  if (minutos < 10) {
    const horaTexto = hora.toString() + '0' + minutos.toString();
    const horaValida = parseInt(horaTexto);
    // console.log(horaValida);
    chatbotStatus(830,1730,statusChatbot,horaValida,dia);
    // chatbotStatus(1800,2400,statusChatbot,horaValida,dia);
  } else {
    const horaTexto = hora.toString() + minutos.toString();
    const horaValida = parseInt(horaTexto);
    // console.log(horaValida);
    chatbotStatus(830,1730,statusChatbot,horaValida,dia);
    // chatbotStatus(1800,2400,statusChatbot,horaValida,dia);
  }

  // chatbotStatus(730,1730,statusChatbot,horaValida);
  // chatbotStatus(200,1000,statusChatbot,horaValida);
}
getTime();


audioPlay = (url) => {
  const audio = new Audio(url);
  audio.play();
}

// notificationNumbers = (tiempo1=250, tiempo2=0) => {
//   const msnItem = document.querySelector('.contador-msn');
//   const msnItems = document.querySelectorAll('.contador-msn');

//   const contenedorBtnChatbot = document.querySelector('#contendor-btn');

//   setTimeout(() => {
//     const contadorMsn = document.createElement('P');
//     contadorMsn.textContent = '2';
//     contadorMsn.classList.add('contador-msn');
//     contenedorBtnChatbot.appendChild(contadorMsn);

//     if (msnItem) {
//       msnItems.forEach( (e) => {
//         e.remove();
//       });
//     }
//   }, tiempo1);

// }


validatePathAudio = () => {
  let pathAudio = '/build/audio/notification-sound.mp3';

  audioPlay(pathAudio);
}

notificationAudios = (tiempo1=0, tiempo2=0) => {
  setTimeout(() => {
    // audioPlay('./audio/notification-sound.mp3');
    validatePathAudio();
  }, tiempo1);

}

// COUNT GLOBAL FISRT CLICK ON BTN BOT
const countSoundsChatbot = (value=0) => {
  if (value < 1) {
    return 0;
  } else {
    notificationAudios(0);
    // notificationNumbers(250);
  }
}   


// Animations
const bodyFullInicio = document.querySelector('#content-inicio');
const animacionesChatbotIn = (element) => {
  element.classList.add('transition');
}

// SHOW HIDE EVENTS COLLAPSE CHATBOT FULL
$('#collapseFullChatbot').on('hide.bs.collapse', function () {
  countSoundsChatbot(0);

  if (($(window).width() < 768)){
    $('body').removeClass('stop-scrolling');
    $('.head-chabot-full').removeClass('fixed');
  }
});
$('#collapseFullChatbot').on('show.bs.collapse', function () {
//   clearTimeout(timeOutShowChatbotInitialAudio);
//   clearTimeout(timeOutShowChatbotInitialNumbers);

  countSoundsChatbot(1);
  animacionesChatbotIn(bodyFullInicio);

  if (($(window).width() < 768)){
    $('body').addClass('stop-scrolling');
    $('.head-chabot-full').addClass('fixed');
  }
});

const collapseChatbotFull = document.querySelector('#collapseFullChatbot');

const openFullChatbotWindowTimer = () => {
  if (collapseChatbotFull.classList.contains('show')) {
    return;
  } else {
    $('#collapseFullChatbot').collapse('show');
  }
}

// Timeouts Begin Page
// const timeOutShowChatbotInitialAudio = setTimeout(notificationAudios, 4750);
// const timeOutShowChatbotInitialNumbers = setTimeout(notificationNumbers, 5000);
// const timeOutShowChatbotFull = setTimeout(openFullChatbotWindowTimer, 15000);


// TRANSITIONS
const transicionStepsChatbot = () => {
  const btnFullToBegin = document.querySelector('#step-full-to-small');
  const contentInicio = document.querySelector('#content-inicio');
  
  const resetContent = () => {
    contentInicio.classList.remove('d-none');
  }
  
  btnChatbot.addEventListener('click', () => {
    btnChatbot.classList.add('d-none');
  })

  btnFullToBegin.addEventListener('click', () => {
    btnChatbot.classList.remove('d-none');

    resetContent();
  })
}
transicionStepsChatbot();



